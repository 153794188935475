export function scrollCv() {
	const cvBtn = document.querySelector('.footer__links');
	let setTimeoutId;

	window.addEventListener( "scroll", function(){
		if(setTimeoutId){
			clearTimeout(setTimeoutId) ;
		}

		cvBtn.classList.remove('is-scroll');

		setTimeoutId = setTimeout( function(){
			cvBtn.classList.add('is-scroll');

			setTimeoutId = null;
		}, 1000) ;
	});
}
