/* nav open/close
*********************************************/
export function nav() {
	const headerBtn = document.querySelector('.header__btn'),
				headerMenu = document.querySelector('.header__menu'),
				nav = document.querySelector('.nav'),
				navBg = document.querySelector('.nav__bg');

	headerBtn.addEventListener('click', () => {
		headerBtn.classList.toggle('is-opened');
		nav.classList.toggle('is-opened');

		if(headerBtn.classList.contains('is-opened')){
			headerMenu.textContent = "close"
		} else {
			headerMenu.textContent = "menu"
		}
	}, false);

	navBg.addEventListener('click', () => {
		headerBtn.classList.remove('is-opened');
		nav.classList.remove('is-opened');
		headerMenu.textContent = "menu";
	}, false);
}
