//import '@babel/polyfill';

//import {common} from "../modules/common";
import {changeImg} from "../modules/changeImg";
import {smoothScroll} from "../modules/smoothScroll";
//import {smoothLink} from "../modules/smoothLink";
//import {pagetop} from "../modules/pagetop";
//import {scrollAnimation} from "../modules/scrollAnimation";
import {nav} from "../modules/nav";
import {scrollCv} from "../modules/scrollCv";
import {sticky} from "../modules/sticky";
//import {archivePager} from "../modules/archivePager";

//common();
changeImg();
smoothScroll();
//smoothLink();
//pagetop();
//scrollAnimation();
nav();
scrollCv();
sticky();
//archivePager();

objectFitImages();

$(function(){
  function _addAjaxZip3(){
    $('.form__zip').on('keyup', function(e){
      AjaxZip3.zip2addr('zip', '', 'pref', 'address-1');
    });
  };
  _addAjaxZip3();
});
